<template>
  <div class="wrapper">
    <admin-title title="案件查询"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="申请人姓名（单位）">
          <el-input v-model.trim="searchData.apply_username" size="small" placeholder="申请人姓名"></el-input>
        </el-form-item>
        <el-form-item label="处理状态">
          <el-select v-model="searchData.handle_status" placeholder="请选择" size="small" clearable>
            <el-option v-for="item in dict_case__status" :key="item.value" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否超期">
          <el-select v-model="searchData.is_timeout" placeholder="请选择" size="small" clearable>
            <el-option v-for="item in dict_timeout_status" :key="item.value" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="委员会">
          <SelectDistrict width="200px" v-model="searchData.search_district_id"></SelectDistrict>
        </el-form-item>
        <el-form-item label="调解结果">
          <el-select v-model="searchData.is_complete" placeholder="请选择" size="small">
            <el-option v-for="item in dict_complete_status" :key="item.value" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            style="width: 220px"
            ref="pick"
            v-model="searchData.timeSlot"
            size="small"
            type="monthrange"
            range-separator="-"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            value-format="yyyy-MM"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.mediate_list_add)"
      style="margin-bottom: 20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'MediateCaseAdd' })"
    >
      添加
    </el-button>
    <el-divider></el-divider>
    <tpTableVue
      :isMediate="true"
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
    <!-- 弹窗 -->
    <el-dialog :title="case_title" :visible.sync="dialogVisible" width="width">
      <el-form ref="form" :model="formData" label-width="80px">
        <!-- 受理 -->
        <div v-if="formData.handle_status == 10">
          <el-form-item label="纠纷类型">
            <SelectDisputeTypeName v-model="formData.acceptData"></SelectDisputeTypeName>
          </el-form-item>
        </div>
        <!-- 指派 -->
        <div v-if="formData.handle_status == 0">
          <el-form-item label="纠纷类型">
            <SelectDisputeTypeName v-model="formData.acceptData"></SelectDisputeTypeName>
          </el-form-item>
          <el-form-item label="片区">
            <SelectDistrict v-model="formData.district_law_uid"></SelectDistrict>
          </el-form-item>
          <el-form-item label="区域">
            <SelectArea v-model="formData.handle_area_detail.area_ids"></SelectArea>
          </el-form-item>
          <el-form-item label="调解员" v-show="formData.handle_area_detail.area_ids.length > 0">
            <SelectLawPeople ref="law" v-model="formData.handle_law_people_id" :level="30" :search_area_id="search_id"></SelectLawPeople>
          </el-form-item>
        </div>
        <!-- 完成 -->
        <div v-if="formData.handle_status == 20">
          <el-form-item label="调解状态">
            <el-select style="width: 400px" v-model="formData.is_complete" size="small" placeholder="请选择">
              <el-option v-for="item in dict_complete_status" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="调解描述">
            <el-input style="width: 400px" type="textarea" v-model="formData.tiaojie_desc" rows="4"></el-input>
          </el-form-item>
          <el-form-item label="附件">
            <UploadImgs :background_images.sync="formData.tiaojie_attachment"></UploadImgs>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitCase">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, delAPI, acceptAPI, completeAPI, designateAPI } from './api'
import { dict_case__status, dict_complete_status, dict_timeout_status } from '@/enum/dict.js'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import SelectDisputeTypeName from '@/views/components/select-dispute-type-name.vue'
import SelectDistrict from '@/views/components/select-district.vue'
import SelectLawPeople from '@/views/components/select-law-people.vue'
import SelectArea from '@/views/components/select-area-all.vue'
import UploadImgs from '@/components/uploader/uploader-imgs.vue'
import tpTableVue from './tp-table.vue'
const columns = [
  {
    label: '案件编号',
    prop: 'no',
    minWidth: '140',
    customRender(h, row) {
      return <p>{row['no'] || '-'}</p>
    }
  },
  {
    label: '申请人姓名（单位）',
    prop: 'apply_username',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['apply_username']}</p>
    }
  },
  {
    label: '申请人联系方式',
    prop: 'apply_mobile',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['apply_mobile']}</p>
    }
  },
  {
    label: '纠纷内容',
    prop: 'mediate_content',
    minWidth: '160',
    customRender(h, row) {
      return (
        <p>
          <el-popover placement="top-start" title="纠纷内容" width="450" trigger="hover" content={row['mediate_content']}>
            <div class="one-line" slot="reference">
              {row['mediate_content']}
            </div>
          </el-popover>
        </p>
      )
    }
  },

  {
    label: '调解结果',
    prop: 'mediate_content',
    minWidth: '120',
    customRender(h, row) {
      return (
        <div>
          <el-tag type="success" size="small" v-show={row['is_complete'] == 0}>
            {row['is_complete_dsc']}
          </el-tag>
          <el-tag type="primary" size="small" v-show={row['is_complete'] == 10}>
            {row['is_complete_dsc']}
          </el-tag>
          <el-tag type="danger" size="small" v-show={row['is_complete'] == 20}>
            {row['is_complete_dsc']}
          </el-tag>
          <el-tag type="warning" size="small" v-show={row['is_complete'] == 30}>
            {row['is_complete_dsc']}
          </el-tag>
        </div>
      )
    }
  },

  {
    label: '处理状态',
    prop: 'handle_status_dsc',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['handle_status_dsc']}</p>
    }
  },

  {
    label: '是否超期',
    prop: 'is_timeout_dsc',
    minWidth: '100',
    customRender(h, row) {
      return (
        <p>
          <el-tag type={row['is_timeout'] == 0 ? 'primary' : 'danger'} size="small">
            {row['is_timeout_dsc']}
          </el-tag>
        </p>
      )
    }
  },
  {
    label: '调解员',
    prop: 'law_people',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['law_people']?.['username']}</p>
    }
  },
  {
    label: '委员会',
    prop: 'district',
    minWidth: '190',
    customRender(h, row) {
      return <p>{row['district']?.['name']}</p>
    }
  },

  {
    label: '添加时间',
    prop: 'created_at',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['created_at']}</p>
    }
  },
  {
    label: '操作',
    fixed: 'right',
    minWidth: '150',
    customRender(h, row) {
      return (
        <div>
          <el-link
            style="margin:0 5px"
            v-show={row['handle_status'] == 10 && this.isShowBtn(this.AUTH_BTN.mediate_list_shouli)}
            type="primary"
            onClick={() => this.handleCase(row)}
          >
            受理
          </el-link>
          <el-link
            style="margin:0 5px"
            v-show={row['handle_status'] == 20 && this.isShowBtn(this.AUTH_BTN.mediate_list_wancheng)}
            type="primary"
            onClick={() => this.handleCase(row)}
          >
            完成
          </el-link>
          <el-link
            style="margin:0 5px"
            v-show={row['handle_status'] == 0 && this.isShowBtn(this.AUTH_BTN.mediate_list_zhipai)}
            type="primary"
            onClick={() => this.handleCase(row)}
          >
            指派
          </el-link>
          <el-link
            style="margin:0 5px"
            v-show={this.isShowBtn(this.AUTH_BTN.mediate_list_chakan)}
            type="primary"
            onClick={() =>
              this.$router.push({
                name: 'MediateCaseDetail',
                params: { id: row['id'] || undefined }
              })
            }
          >
            查看
          </el-link>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.mediate_list_edit)}
            style="margin:0 5px"
            type="primary"
            onClick={() =>
              this.$router.push({
                name: 'MediateCaseEdit',
                params: { id: row['id'] || undefined }
              })
            }
          >
            编辑
          </el-link>
          <el-link
            style="margin:0 5px"
            v-show={this.isShowBtn(this.AUTH_BTN.mediate_list_del)}
            type="primary"
            onClick={() => this.goRemove(row['id'])}
          >
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { SelectDisputeTypeName, SelectDistrict, SelectLawPeople, SelectArea, UploadImgs, tpTableVue },
  data() {
    return {
      dialogVisible: false,
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [],
      searchData: {
        apply_username: '',
        handle_status: '',
        search_district_id: '',
        timeSlot: [],
        is_timeout: '',
        is_complete: ''
      },
      dict_case__status,
      dict_complete_status,
      dict_timeout_status,

      formData: {
        id: '',
        handle_status: '',
        acceptData: '', //受理
        is_complete: '', //完成
        tiaojie_desc: '', //完成
        tiaojie_attachment: [],
        district_law_uid: '', //指派
        handle_law_people_id: '', //指派
        handle_area_detail: {
          area_ids: []
        } //指派
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  computed: {
    case_title() {
      let title = ''
      const { handle_status } = this.formData
      if (handle_status == 0) {
        title = '案件指派'
      } else if (handle_status == 10) {
        title = '案件受理'
      } else if (handle_status == 20) {
        title = '案件完成'
      }
      return title
    },
    search_id() {
      const { handle_area_detail } = this.formData
      return handle_area_detail.area_ids[handle_area_detail.area_ids.length - 1]
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    },
    'formData.handle_area_detail.area_ids': {
      handler(newV, oldV) {
        this.$nextTick(() => {
          const { handle_status } = this.formData
          if (handle_status == 0) this.$refs.law.getList()
        })
      }
    },
    $route: {
      immediate: true,
      handler(newV, oldV) {
        const { search_district_id, start_month, end_month } = this.$route.query
        if (search_district_id) this.searchData.search_district_id = search_district_id
        if (start_month && end_month) this.searchData.timeSlot = [start_month, end_month]
      }
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { apply_username, handle_status, search_district_id, timeSlot, is_timeout, is_complete } = this.searchData
      let params = { page: this.currentPage, page_size: this.page_size, apply_username, handle_status, search_district_id, is_timeout, is_complete }
      if (timeSlot && timeSlot.length > 0) {
        params.start_month = timeSlot[0]
        params.end_month = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    goRemove(id) {
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 打开弹窗
    handleCase({ id, handle_status }) {
      Object.assign(this._data.formData, this.$options.data().formData)
      this.formData.handle_status = handle_status
      this.formData.id = id
      this.dialogVisible = true
    },
    // 提交
    submitCase() {
      const {
        id,
        handle_status,
        acceptData,
        is_complete,
        tiaojie_desc,
        tiaojie_attachment,
        district_law_uid,
        handle_law_people_id,
        handle_area_detail
      } = this.formData
      if (handle_status == 0) {
        // 指派
        const { dispute_type, dispute_type_id } = JSON.parse(acceptData)
        const data = { id, district_law_uid, handle_law_people_id, handle_area_detail, dispute_type, dispute_type_id }
        designateAPI(data).then(() => {
          this.$message.success('指派成功')
          this.getList()
          this.dialogVisible = false
        })
      } else if (handle_status == 10) {
        // 受理
        const { dispute_type, dispute_type_id } = JSON.parse(acceptData)
        const data = { id, dispute_type, dispute_type_id }
        acceptAPI(data).then(() => {
          this.$message.success('受理成功')
          this.getList()
          this.dialogVisible = false
        })
      } else if (handle_status == 20) {
        // 完成
        const data = { id, is_complete, tiaojie_desc, tiaojie_attachment }
        completeAPI(data).then(() => {
          this.$message.success('完成成功')
          this.getList()
          this.dialogVisible = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 15px;
        margin-bottom: 5px;
        &:nth-child(1) {
          .el-input {
            width: 200px;
          }
        }
        &:nth-child(2) {
          .el-select {
            width: 250px;
          }
        }
        &:nth-child(3) {
          .el-select {
            width: 250px;
          }
        }
        &:nth-child(4) {
          .el-select {
            width: 250px;
          }
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0 17px 0 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }

  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .one-line {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    -webkit-line-clamp: 2; // 设置两行文字溢出
    display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    overflow: hidden; /** 隐藏超出的内容 **/
  }
}
</style>
