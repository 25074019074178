<template>
  <el-select
    :style="{ width: width }"
    size="small"
    v-model="selectValue"
    :loading="isLoading"
    filterable
    remote
    clearable
    :remote-method="getList"
    placeholder="请选择或输入关键字"
  >
    <!-- { dispute_type_id: item.id, dispute_type: item.name } -->
    <el-option
      v-for="item in list"
      :label="item.name"
      :value="JSON.stringify({ dispute_type_id: item.id, dispute_type: item.name })"
      :key="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { getDisputeTypeListAPI } from './api.js'
export default {
  name: 'SelectJob',

  props: {
    value: {
      type: [String, Number, Object],
      default: ''
    },
    width: {
      type: String,
      default: '400px'
    }
  },

  computed: {
    selectValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getList('')
  },

  methods: {
    async getList(query) {
      let params = {
        page: 1,
        page_size: 50,
        name: query
      }
      let res = await getDisputeTypeListAPI(params)
      this.list = res.data
    }
  }
}
</script>

<style></style>
